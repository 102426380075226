<template>
  <section class="ds s-py-45 s-py-xl-75 c-gutter-60">
    <!-- <section class="ds s-py-90 s-py-xl-150 c-gutter-60"> -->
    <div class="container">
      <div class="row">
        <main class="col-lg-12">
          <article>
            <!-- <header class="entry-header">
              <h1 class="entry-title">{{ getCurrentCategory.subtitle }}</h1>
            </header>-->
            <!-- .entry-header -->
            <div class="entry-content">
              <div class="woocommerce">
                <div class="woocommerce-MyAccount-content">
                  <form id="child-form" role="form" style="display: block">
                    <p class="mm-font __mm">
                      အောက်ပါပေးထားသော Form အချက်အလက်များကို ဖြည့်ပေးပါ
                    </p>
                    <label class="form-group">
                      <span class="mm-font __mm">အမည်</span>
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        v-model="nawin.name"
                        required
                      />
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">မွေးသက္ကရာဇ်</span>
                      <!-- <input
                        type="date"
                        class="form-control"
                        name="birth_date"
                        v-model="nawin.birth_date"
                        required
                      /> -->

                      <md-datepicker
                        value-format="YYYY-MM-DD"
                        class="input-container"
                        v-model="nawin.birth_date"
                      >
                      </md-datepicker>
                    </label>
                    <label class="form-group">
                      <span class="mm-font __mm">နေ့နံ</span>
                      <select
                        class="mm-font __mm"
                        name="nyih_nan"
                        v-model="nawin.nyih_nan"
                        required
                      >
                        <option value="တနင်္ဂနွေ" class="__mm">
                          တနင်္ဂနွေ
                        </option>
                        <option value="တနင်္လာ" class="__mm">တနင်္လာ</option>
                        <option value="အင်္ဂါ" class="__mm">အင်္ဂါ</option>
                        <option value="ဗုဒ္ဓဟူး" class="__mm">ဗုဒ္ဓဟူး</option>
                        <option value="ရာဟု" class="__mm">ရာဟု</option>

                        <option value="ကြာသပတေး" class="__mm">ကြာသပတေး</option>
                        <option value="သောကြာ" class="__mm">သောကြာ</option>
                        <option value="စနေ" class="__mm">စနေ</option>
                      </select>
                    </label>
                    <input
                      type="radio"
                      name="marital_status"
                      v-model="nawin.marital_status"
                      value="Yes"
                      required
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်ရှိ</span>
                    <input
                      type="radio"
                      name="marital_status"
                      v-model="nawin.marital_status"
                      value="No"
                      required
                    />
                    <span for class="mm-font __mm">အိမ်ထောင်မရှိ</span>
                    <br />
                    <br />
                    <label class="form-group">
                      <span for class="mm-font __mm">အလုပ်အကိုင်</span>
                      <div
                        style="
                          display: grid;
                          grid-template-rows: 1fr 1fr;
                          gap: 5px;
                        "
                      >
                        <select
                          name="job"
                          class="mm-font __mm"
                          v-model="nawin.job"
                          required
                        >
                          <option value="" selected hidden>
                            အလုပ်အကိုင် အမျိုးအစား
                          </option>
                          <option value="အစိုးရဝန်ထမ်း">အစိုးရဝန်ထမ်း</option>
                          <option value="ကုမ္ပဏီဝန်ထမ်း">ကုမ္ပဏီဝန်ထမ်း</option>
                          <option value="ကိုယ်ပိုင်စီးပွားရေး">
                            ကိုယ်ပိုင်စီးပွားရေး
                          </option>
                          <option value="ကျောင်းသား/သူ">ကျောင်းသား/သူ</option>
                          <option value="အခြား">အခြား</option>
                        </select>
                        <input
                          type="text"
                          class="form-control"
                          name="job_detail"
                          v-model="nawin.job_detail"
                          placeholder="အလုပ်ကိုင်အသေးစိတ်"
                        />
                      </div>
                    </label>
                    <input
                      type="radio"
                      name="gender"
                      value="Male"
                      v-model="nawin.gender"
                      required
                    />
                    <span for class="mm-font __mm">ကျား</span>
                    <input
                      type="radio"
                      name="gender"
                      value="Female"
                      v-model="nawin.gender"
                      required
                    />
                    <span for class="mm-font __mm">မ</span>
                    <br />
                    <br />

                    <label class="form-group">
                      <span for class="mm-font __mm">နေရပ်လိပ်စာ</span>
                      <div
                        style="
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          gap: 10px;
                        "
                      >
                        <select
                          v-model="nawin.address_division"
                          required
                          class="mm-font __mm"
                          @change="handleDivisionChange"
                        >
                          <option value="" selected hidden>
                            ပြည်နယ်/တိုင်း
                          </option>
                          <option
                            v-for="d in divisions"
                            :data-nrc="d.nrc_id"
                            :value="d.name"
                            :key="d.name"
                            class="__mm"
                          >
                            {{ d.name }}
                          </option>
                        </select>
                        <select
                          v-model="nawin.address_township"
                          required
                          class="mm-font __mm"
                        >
                          <option value="" selected hidden>မြို့နယ်</option>
                          <option
                            value=""
                            disabled
                            v-if="townships.length == 0"
                          >
                            တိုင်း/ ပြည်နယ် ကိုအရင်ရွေးချယ်ပါ
                          </option>
                          <option
                            v-for="t in townships"
                            :value="t.name"
                            :key="t.name"
                            class="__mm"
                          >
                            {{ t.name }}
                          </option>
                        </select>
                      </div>
                      <input
                        type="text"
                        class="mm-font __mm form-control"
                        placeholder="ရပ်ကွက်/ လမ်း အသေးစိတ်"
                        v-model="nawin.address"
                        required
                      />
                    </label>

                    <br />
                    <br />

                    <label class="form-group">
                      <span for class="mm-font __mm"
                        >Viber အသုံးပြုသောဖုန်းနံပါတ်</span
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="phone_number"
                        v-model="nawin.phone_number"
                        @input="onlyDigit"
                        placeholder="09xxxxxxxxx"
                        required
                      />
                    </label>
                    <p>
                      နဝင်းပြုလုပ်ရန်အတွက် ကြိုတင်၍ Booking လုပ်ပေးပါရန် နှင့်
                      နဝင်းထိုင်မည့်သူ၏ဓာတ်ပုံ ကို Viber Number - 09456880335
                      သို့ပို့ပေးပါ။ မှတ်ချက်။ ။ မိမိမွေးနေ့တွင်
                      နဝင်းပွဲပြုလုပ်ပေးပါသည်။
                    </p>

                    <p>
                      <input
                        type="submit"
                        @click.prevent="paymentProvider()"
                        :disabled="disabled"
                        class="woocommerce-Button button mm-font __mm"
                        name="save_account_details"
                        value="အတည်ပြုမည်"
                      />
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <!-- .entry-content -->
          </article>
        </main>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { Horo, HTTP } from "@core/lib/http-common";
import { getFormData, checkFormModel } from "@core/lib/form-data";
import { makePayment, onePay, citizenPay } from "../js/payment";
import { getDivisions, getTownships } from "../js/addressInfo";
import moment from "moment";

export default {
  name: `Nawin`,
  data() {
    return {
      actionBtnMethod: "",
      disabled: false,
      onepayPhoneNumber: "",
      divisions: [],
      townships: [],
      // promocode: "",
      nawin: {
        name: "",
        birth_date: moment(new Date("01/01/1930")).format("YYYY-MM-DD"),
        nyih_nan: "",
        address: "",
        address_division: "",
        address_township: "",
        phone_number: "",
        order_id: "0",
        gender: "",
        user_id: "0",
        marital_status: "",
        price: 18000,
        job: "",
        job_detail: "",
      },
    };
  },
  async created() {
    this.divisions = await getDivisions();
    this.actionBtnMethod = this.$store.state.paymentName;
    this.onepayPhoneNumber =
      this.$store.state.paymentName == "onepay"
        ? this.$store.state.onepayPhoneNumber
        : "";
  },

  computed: {
    ...mapGetters({
      getUserToken: "userStore/getUserToken",
      getResponse: "userStore/getResponse",
    }),
  },

  methods: {
    async handleDivisionChange(e) {
      let nrc_id = e.target.options[e.target.selectedIndex].dataset.nrc;
      this.townships = await getTownships(nrc_id);
    },

    onlyDigit($event) {
      let key = $event.keyCode || $event.charCode;
      if ($event.target.value.length > 11 && key != 8) {
        $event.target.value = $event.target.value.substr(
          0,
          $event.target.value.length - 1
        );
      }

      $event.target.value = $event.target.value.replace(/[^\d]/gi, "");
    },

    paymentProvider() {
      let getPaymentName = this.actionBtnMethod;
      switch (getPaymentName) {
        case "mpitesan":
          this.makePayment();
          break;
        case "onepay":
          this.onePayProvider();
          break;
        case "citizenpay":
          this.citizenPayProvicer();
          break;
        case "bppay":
          this.bpPay();
          break;
        default:
          this.precreate();
          break;
      }
    },

    async citizenPayProvicer() {
      if (checkFormModel(this.nawin)) {
        this.disabled = true;

        let formdata = getFormData(this.nawin);
        formdata.append("user_phone", this.nawin.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        formdata.append("srcFrom", payment_method);
        let cdata = {
          category_id: 2020220,
          amount: this.nawin.price,
          category_name: "nawin",
        };
        await citizenPay([
          "1875/nawin/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.nawin.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              window.location.href = data.paymentGatway;
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async onePayProvider() {
      if (checkFormModel(this.nawin)) {
        this.disabled = true;
        this.nawin.user_id = this.onepayPhoneNumber;

        let formdata = getFormData(this.nawin);
        formdata.append("user_phone", this.nawin.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        formdata.append("srcFrom", payment_method);
        let cdata = {
          category_id: 2020220,
          amount: this.nawin.price,
          category_name: "nawin",
          phone_number:
            this.onepayPhoneNumber == ""
              ? this.direct.phone_number
              : this.onepayPhoneNumber,
        };
        await onePay([
          "1875/nawin/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.nawin.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async bpPay() {
      if (checkFormModel(this.nawin)) {
        this.disabled = true;

        let formdata = getFormData(this.nawin);
        formdata.append("user_phone", this.nawin.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        formdata.append("srcFrom", "PWA BPPay");
        let cdata = {
          category_id: 2020220,
          amount: this.nawin.price,
          category_name: "nawin",
          // promocode: this.promocode,
          phone_number: this.nawin.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/nawin/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.nawin.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //     name: "paymentPortal",
              // });
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortalV3",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "mpitesan" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    async makePayment() {
      if (checkFormModel(this.nawin)) {
        this.disabled = true;
        let formdata = getFormData(this.nawin);
        formdata.append("user_phone", this.nawin.phone_number);
        let payment_method =
          this.$store.state.paymentName == " "
            ? "kbz"
            : this.$store.state.paymentName;
        formdata.append("payment_method", payment_method);
        formdata.append("srcFrom", payment_method);
        let cdata = {
          category_id: 2020220,
          amount: this.nawin.price,
          category_name: "nawin",
          // promocode: this.promocode,
          phone_number: this.nawin.phone_number,
          payment_name: this.actionBtnMethod,
        };
        await makePayment([
          "1875/nawin/horoscope",
          formdata,
          cdata,
          this.$store.state.backendUrl,
          this.nawin.price,
        ])
          .then((data) => {
            if (data.status == 200) {
              // console.log(data);
              this.$store.state.order_id = data.order_id;
              this.$store.state.amount = data.amount;
              // this.$router.push({
              //     name: "paymentPortal",
              // });
              if (!data.payment_status) {
                this.$router.push({
                  name: "paymentPortal",
                });
              } else {
                this.$router.push({
                  name: "PaymentSuccess",
                  params: { payment: "mpitesan" },
                });
              }
            } else {
              this.$swal("Oops", "Something went wrong!", "error");
            }
          })
          .catch(() => {
            this.$swal("Oops", "Something went wrong!", "error");
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },

    precreate() {
      if (checkFormModel(this.nawin)) {
        this.disabled = true;
        HTTP.defaults.headers.Authorization = `Bearer ${this.getUserToken}`;
        HTTP.post("subscription/precreate", {
          category_id: 2020220,
          amount: this.nawin.price,
          category_name: "nawin",
        })
          .then((response) => {
            const res = response.data;
            this.nawin.order_id = res.order_id;
            this.nawin.user_id = res.user_id;
            let formdata = getFormData(this.nawin);
            formdata.append(
              "user_phone",
              this.getResponse.customer_info.msisdn
            );
            formdata.set("price", res.amount);
            formdata.append("payment_method", "KBZPay");
            formdata.append("srcFrom", "KBZPay");
            Horo.post("1875/nawin/horoscope", formdata).then((response) => {
              this.res_status = response.data.status;
              this.kbzpay.startPay(
                res.prepay_id,
                res.order_info,
                res.sign_app,
                ({ resultCode, lang }) => {
                  if (resultCode == 1) {
                    if (lang == "en") {
                      this.$swal("Success", "Your Purchase Success", "success");
                      this.$router.push({ name: "home" });
                    }
                    if (lang == "my") {
                      this.$swal("အောင်မြင်ပါသည်", "ဝယ်ယူပြီးပါပြီ", "success");
                      this.$router.push({ name: "home" });
                    }
                  }
                  if (resultCode == 2) {
                    if (lang == "en") {
                      this.$swal("Failed", "Your Purchase Failed", "error");
                      this.disabled = false;
                    }
                    if (lang == "my") {
                      this.$swal("မအောင်မြင်ပါ", "ထပ်မံကြိုးစားပေးပါ", "error");
                      this.disabled = false;
                    }
                  }
                },
                () => {
                  this.$swal("Failed", "Your Purchase Failed", "error");
                  this.disabled = false;
                }
              );
            });
          })
          .catch((error) => {
            console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        this.$swal("Oops", "You need to fill all fields", "error");
      }
    },
  },
};
</script>

<style scoped>
input[type="radio"] {
  display: inline;
  margin-top: 8px;
  margin-left: 3px;
}

input::placeholder {
  color: grey !important;
}

.input-container {
  display: flex;
  border: 1px solid #000;
  background-color: #f2eff7;
  color: #3e1e68;
  border-radius: 25px;
  padding: 8px 0px 0px 10px;
}
.md-field.md-theme-default:after {
  background-color: rgba(0, 0, 0, 0);
}
.md-button.md-theme-default {
  margin: 0px 16px 0px 0px;
}
</style>
