var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"ds s-pt-30 s-pb-20 s-pt-xl-60 s-pb-xl-40 c-gutter-60 c-mb-30"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row",attrs:{"id":"box"}},[(
          _vm.paymentName == ' ' ||
          _vm.paymentName == 'mpitesan' ||
          _vm.paymentName == 'citizenpay' ||
          _vm.paymentName == 'bppay' ||
          _vm.paymentName == 'onepay'
        )?_c('div',{staticClass:"col-xl-4 col-lg-6 col-6"},[_c('div',{staticClass:"vertical-item text-center service-layout-1 content-padding",on:{"click":function($event){return _vm.$router.push({
              name: 'category',
              params: { categoryName: 'tarot-shuffle' },
            })}}},[_c('div',{staticClass:"item-media"},[_c('img',{attrs:{"src":_vm.displayByPayment[2].image,"alt":"img"}})])])]):_vm._e(),(
          _vm.paymentName == ' ' ||
          _vm.paymentName == 'mpitesan' ||
          _vm.paymentName == 'citizenpay' ||
          _vm.paymentName == 'bppay' ||
          _vm.paymentName == 'onepay'
        )?_c('div',{staticClass:"col-xl-4 col-lg-6 col-6"},[_c('div',{staticClass:"vertical-item text-center service-layout-1 content-padding",on:{"click":function($event){return _vm.$router.push({
              name: 'category',
              params: { categoryName: 'compatability-love' },
            })}}},[_c('div',{staticClass:"item-media"},[_c('img',{attrs:{"src":_vm.displayByPayment[3].image,"alt":"img"}})])])]):_vm._e(),(
          _vm.paymentName == ' ' ||
          _vm.paymentName == 'onepay' ||
          _vm.paymentName == 'mpitesan' ||
          _vm.paymentName == 'citizenpay' ||
          _vm.paymentName == 'bppay'
        )?_c('div',{staticClass:"col-xl-4 col-lg-6 col-6"},[_c('div',{staticClass:"vertical-item text-center service-layout-1 content-padding",on:{"click":function($event){return _vm.$router.push({
              name: 'category',
              params: { categoryName: 'tarot' },
            })}}},[_c('div',{staticClass:"item-media"},[_c('img',{attrs:{"src":_vm.displayByPayment[1].image,"alt":"img"}})])])]):_vm._e(),(
          _vm.paymentName == ' ' ||
          _vm.paymentName == 'onepay' ||
          _vm.paymentName == 'mpitesan' ||
          _vm.paymentName == 'bppay'
        )?_c('div',{staticClass:"col-xl-4 col-lg-6 col-6"},[_c('div',{staticClass:"vertical-item text-center service-layout-1 content-padding",on:{"click":function($event){return _vm.$router.push({
              name: 'category',
              params: { categoryName: 'newyearbaydin' },
            })}}},[_c('div',{staticClass:"item-media"},[_c('img',{attrs:{"src":_vm.new_year_baydin.image,"alt":"img"}})])])]):_vm._e(),_vm._l((_vm.displayCategories),function(category){return _c('div',{key:category.id,staticClass:"col-xl-4 col-lg-6 col-6"},[_c('div',{staticClass:"vertical-item text-center service-layout-1 content-padding",on:{"click":function($event){return _vm.$router.push({
              name: 'category',
              params: { categoryName: category.name },
            })}}},[_c('div',{staticClass:"item-media"},[_c('img',{attrs:{"src":category.image,"alt":"img"}})])])])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }